// Exclude from page generation
export const excludedRoutes = [/\/components(\/).*?$/];

// Generate page, exclude from sitemap
export const excludedSitemap = [
  /\/preview(\/)?$/,
  /\/not-found(\/)?$/,
  /\/404(\/)?$/,
];

export default {
  home_page: {
    "en-us": "/",
    "ko-kr": "/ko/",
  },
  project_hub: {
    "en-us": "/project",
    "ko-kr": "/ko/project",
  },
  project_page: {
    "en-us": "/project",
    "ko-kr": "/ko/project",
  },
  bim_hub: {
    "en-us": "/bim",
    "ko-kr": "/ko/bim",
  },
  bim_page: {
    "en-us": "/bim",
    "ko-kr": "/ko/bim",
  },
  contact_page: {
    "en-us": "/contact",
    "ko-kr": "/ko/contact",
  },
  about_page: {
    "en-us": "/about",
    "ko-kr": "/ko/about",
  },
  error_page: {
    "en-us": "/not-found/",
    "ko-kr": "/ko/not-found/",
  },
};
