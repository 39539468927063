import { default as PrismicPreviewRybkITQyBsMeta } from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_sass-embedd_ziso3wafdfuri567kclq6fmfe4/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as _91uid_935n55nO060EMeta } from "/opt/build/repo/pages/bim/[uid].vue?macro=true";
import { default as index2iInh9juAsMeta } from "/opt/build/repo/pages/bim/index.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91uid_93PCVqeaRYlEMeta } from "/opt/build/repo/pages/project/[uid].vue?macro=true";
import { default as indexnpobEWMjMtMeta } from "/opt/build/repo/pages/project/index.vue?macro=true";
import { default as slice_45simulatoraGnv8AQLQlMeta } from "/opt/build/repo/pages/slice-simulator.vue?macro=true";
import { default as component_45stubMxfbEfBvY4Meta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMxfbEfBvY4 } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__j3h7pxg66krwy67wot72uf43ha/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "prismic-preview___en",
    path: "/preview",
    component: () => import("/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_sass-embedd_ziso3wafdfuri567kclq6fmfe4/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "prismic-preview___ko",
    path: "/ko/preview",
    component: () => import("/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_sass-embedd_ziso3wafdfuri567kclq6fmfe4/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "about___en",
    path: "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "about___ko",
    path: "/ko/about",
    meta: aboutlv6i1FL0CfMeta || {},
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "bim-uid___en",
    path: "/bim/:uid()",
    meta: _91uid_935n55nO060EMeta || {},
    component: () => import("/opt/build/repo/pages/bim/[uid].vue")
  },
  {
    name: "bim-uid___ko",
    path: "/ko/bim/:uid()",
    meta: _91uid_935n55nO060EMeta || {},
    component: () => import("/opt/build/repo/pages/bim/[uid].vue")
  },
  {
    name: "bim___en",
    path: "/bim",
    meta: index2iInh9juAsMeta || {},
    component: () => import("/opt/build/repo/pages/bim/index.vue")
  },
  {
    name: "bim___ko",
    path: "/ko/bim",
    meta: index2iInh9juAsMeta || {},
    component: () => import("/opt/build/repo/pages/bim/index.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/opt/build/repo/pages/contact.vue")
  },
  {
    name: "contact___ko",
    path: "/ko/contact",
    component: () => import("/opt/build/repo/pages/contact.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "index___ko",
    path: "/ko",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "project-uid___en",
    path: "/project/:uid()",
    meta: _91uid_93PCVqeaRYlEMeta || {},
    component: () => import("/opt/build/repo/pages/project/[uid].vue")
  },
  {
    name: "project-uid___ko",
    path: "/ko/project/:uid()",
    meta: _91uid_93PCVqeaRYlEMeta || {},
    component: () => import("/opt/build/repo/pages/project/[uid].vue")
  },
  {
    name: "project___en",
    path: "/project",
    meta: indexnpobEWMjMtMeta || {},
    component: () => import("/opt/build/repo/pages/project/index.vue")
  },
  {
    name: "project___ko",
    path: "/ko/project",
    meta: indexnpobEWMjMtMeta || {},
    component: () => import("/opt/build/repo/pages/project/index.vue")
  },
  {
    name: "slice-simulator___en",
    path: "/slice-simulator",
    component: () => import("/opt/build/repo/pages/slice-simulator.vue")
  },
  {
    name: "slice-simulator___ko",
    path: "/ko/slice-simulator",
    component: () => import("/opt/build/repo/pages/slice-simulator.vue")
  },
  {
    name: component_45stubMxfbEfBvY4Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubMxfbEfBvY4
  },
  {
    name: component_45stubMxfbEfBvY4Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubMxfbEfBvY4
  },
  {
    name: component_45stubMxfbEfBvY4Meta?.name,
    path: "/en-us-sitemap.xml",
    component: component_45stubMxfbEfBvY4
  },
  {
    name: component_45stubMxfbEfBvY4Meta?.name,
    path: "/ko-kr-sitemap.xml",
    component: component_45stubMxfbEfBvY4
  }
]