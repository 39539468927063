
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ko"
]

export const localeLoaders = {
  "en": [],
  "ko": []
}

export const vueI18nConfigs = [
  () => import("../config/i18n/index.ts?hash=32c1c5c7&config=1" /* webpackChunkName: "@_config_i18n_index_32c1c5c7" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "@/config/i18n/index",
  "locales": [
    {
      "code": "en",
      "language": "en-us",
      "name": "English"
    },
    {
      "code": "ko",
      "language": "ko-kr",
      "name": "한국어"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://dev.ask-archi.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "en": "/",
      "ko": "/"
    },
    "project/[uid]": {
      "en": "/project/[uid]",
      "ko": "/project/[uid]"
    },
    "project": {
      "en": "/project",
      "ko": "/project"
    },
    "bim/[uid]": {
      "en": "/bim/[uid]",
      "ko": "/bim/[uid]"
    },
    "bim": {
      "en": "/bim",
      "ko": "/bim"
    },
    "slice-simulator": {
      "en": "/slice-simulator",
      "ko": "/slice-simulator"
    },
    "about": {
      "en": "/about",
      "ko": "/about"
    },
    "contact": {
      "en": "/contact",
      "ko": "/contact"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-us",
    "name": "English",
    "files": []
  },
  {
    "code": "ko",
    "language": "ko-kr",
    "name": "한국어",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
