export default defineNuxtRouteMiddleware((to, _) => {
  if (!to.path.endsWith("/") && process.client) {
    return navigateTo(
      { path: to.path + "/", query: to.query, hash: to.hash },
      {
        redirectCode: 301,
      },
    );
  }
});
