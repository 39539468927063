<script setup lang="ts">
import type { LinkField } from "@prismicio/client";
import type { PropType } from "vue";

const props = defineProps({
  linkObject: {
    type: Object as PropType<LinkField>,
    default: undefined,
  },
  url: {
    type: String,
    default: undefined,
  },
  size: {
    type: String as PropType<"sm" | "md" | "lg">,
    default: "md",
  },
});
const { asLink } = usePrismic();

const computedLink = computed(() => {
  if (!props.url && !props.linkObject) {
    return undefined;
  }

  if (props.url) {
    return asLink({
      link_type: "Web",
      url: props.url,
    });
  }
  return asLink(props.linkObject);
});

const component = computed(() => {
  if (computedLink.value) {
    return "a";
  } else {
    return "button";
  }
});
</script>

<template>
  <component
    :is="component"
    :href="computedLink"
    class="flex items-center justify-center rounded-sm border-[1px] border-black bg-black uppercase text-white transition-colors duration-300 hover:bg-opacity-80"
    :class="{
      'px-3 py-2 text-sm': size === 'sm',
      'px-3 py-2 text-base': size === 'md',
      'px-4 py-3 text-lg': size === 'lg',
    }"
  >
    <slot></slot>
  </component>
</template>
