<script setup lang="ts">
import { mdiWeb } from "@mdi/js";

const { locales, locale } = useI18n();
const { alternateLanguages } = storeToRefs(usePageStore());
const { asLink } = usePrismic();

const langs = computed(() =>
  locales.value.map((l) => {
    const url =
      asLink(alternateLanguages.value.find((v) => v.lang === l.language)) ||
      asLink({
        link_type: "Document",
        type: "home_page",
        id: "home_page",
        lang: l.language,
      });

    return {
      label: l.code.toUpperCase(),
      url: l.code === locale.value ? "" : (url as string),
    };
  }),
);
const isPageLoaded = ref(false);

onNuxtReady(() => {
  isPageLoaded.value = true;
});
</script>

<template>
  <div
    class="transition-color relative flex items-center text-base text-black opacity-0 duration-500"
    :class="{ ['!opacity-100']: isPageLoaded }"
  >
    <ClientOnly>
      <div class="flex items-center">
        <div class="mr-1 h-[18px] w-[18px] translate-y-[1px]">
          <BIcon :path="mdiWeb" class="h-full w-full" />
        </div>
        <NuxtLink
          v-if="langs[0].url"
          :to="langs[0].url"
          class="flex items-center hover:text-gray-500"
        >
          {{ langs[0].label }}
        </NuxtLink>
        <span v-else class="underline">
          {{ langs[0].label }}
        </span>
        <span class="mx-[2px] text-xs">/</span>
        <NuxtLink
          v-if="langs[1].url"
          :to="langs[1].url"
          class="flex items-center hover:text-gray-500"
        >
          {{ langs[1].label }}
        </NuxtLink>
        <span v-else class="underline">
          {{ langs[1].label }}
        </span>
      </div>
    </ClientOnly>
  </div>
</template>

<style module lang="scss">
.selector {
  &::after {
    position: absolute;
    top: -6px;
    left: 50%;
    border-color: transparent transparent white;
    border-width: 0 5px 8.66px;
    transform: translateX(-50%);
    content: "";
  }
}
</style>
